@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.polaroid-section {
  min-height: 436px;
  color: #fff;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
}

.body-container {
  padding: 32px 32px fn.spacing(60); // 32px 32px 416px

  > * {
    padding-bottom: 32px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.polaroid-grid-item {
  position: relative;
}

.polaroid-section-content {
  min-height: inherit;
  width: 100%;
  position: relative;
}

.polaroid-image {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: transform 0.6s ease-in-out;
  opacity: 0;

  &[data-loaded='true'] {
    opacity: 1;

    // This pseudo element covers the original photo part of the Polaroid images and is used
    // to fake the exposure animation
    &::after {
      // one-off custom override color to match the color of the polaroid frame
      background: #ebebeb;
      content: '';
      height: 152px;
      position: absolute;
      transform: translate(-137px, 11px);
      width: 123px;
      z-index: 1;
      animation-duration: 7s;
      animation-name: expose-polaroid;
      opacity: 0;
    }
  }
}

.polaroid-origin-mobile {
  .polaroid-image[data-loaded='true'] {
    &:nth-child(1) {
      transform: rotate(8.15deg) translate(-225px, -269px);
    }

    &:nth-child(2) {
      transform: rotate(-9.2deg) translate(-77px, -194px);
    }

    &:nth-child(3) {
      transform: rotate(-4.87deg) translate(-152px, -65px);
    }

    &:nth-child(4) {
      display: none;
    }

    &:nth-child(5) {
      transform: rotate(7.16deg) translate(116px, -292px);
      z-index: 1;
    }

    &:nth-child(6) {
      transform: rotate(-5deg) translate(152px, -110px);
    }

    &:nth-child(7) {
      transform: rotate(-8.47deg) translate(-48px, 15px);
      z-index: 1;
    }

    &:nth-child(8) {
      transform: rotate(2deg) translate(-4px, -99px);
    }

    &:nth-child(9) {
      transform: rotate(5.28deg) translate(99px, -18px);
    }
  }
}

@keyframes expose-polaroid {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Centered "stack" of polaroids
.polaroid-origin {
  position: absolute;
  height: 174px;
  width: 150px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.rule1 {
  position: absolute;
  height: 38px;
  width: 186px;
  transform: translate(-35px, -256px);

  div {
    height: 80px;
  }
}

.rule2 {
  position: absolute;
  height: 45px;
  width: 99px;
  transform: translate(40px, -174px);
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .body-container {
    padding: 0 32px 0 0;
  }

  .polaroid-origin {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  .polaroid-origin-1 {
    .polaroid-image {
      &[data-loaded='true']:nth-child(1) {
        transform: rotate(8.15deg) translate(-55px, -109px);
      }

      &[data-loaded='true']:nth-child(2) {
        transform: rotate(-9.2deg) translate(41px, -10px);
      }

      &[data-loaded='true']:nth-child(3) {
        transform: rotate(-4.87deg) translate(-45px, 120px);
      }
    }
  }

  .polaroid-origin-2 {
    .polaroid-image {
      &[data-loaded='true']:nth-child(1) {
        transform: rotate(-3.12deg) translate(-53px, -145px);
      }

      &[data-loaded='true']:nth-child(2) {
        transform: rotate(7.16deg) translate(101px, -153px);
      }

      &[data-loaded='true']:nth-child(3) {
        transform: rotate(1.39deg) translate(29px, -57px);
      }

      &[data-loaded='true']:nth-child(4) {
        transform: rotate(-8.47deg) translate(-104px, 4px);
      }

      &[data-loaded='true']:nth-child(5) {
        transform: rotate(-8.73deg) translate(116px, 77px);
      }

      &[data-loaded='true']:nth-child(6) {
        transform: rotate(5.28deg) translate(27px, 139px);
      }
    }
  }

  .rule1 {
    height: 45px;
    width: 217px;
    transform: translate(128px, -95px);
  }

  .rule2 {
    transform: translate(-128px, 225px);
  }
}
